/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --gray-900: #0e0c0c;
  --gray-800: #302728;
  --gray-700: #514344;
  --gray-600: #735e60;
  --gray-500: #937c7e;
  --gray-400: #cabfc0;
  --gray-300: #d6cdce;
  --gray-200: #e2dcdd;
  --gray-100: #eeebeb;
  --gray-050: #faf9f9;

  --primary-900: #52141a;
  --primary-800: #6d1b23;
  --primary-700: #88222c;
  --primary-600: #a42835;
  --primary-500: #bf2f3d;
  --primary-400: #d0404e;
  --primary-300: #d75b68;
  --primary-200: #dd7781;
  --primary-100: #e4929a;
  --primary-050: #ebadb3;
}
